import React from 'react';
import Link from '../link';
import * as styles from './more.module.scss';

class More extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      title, url, id, isMargin, fontSize,
    } = this.props;
    const s = isMargin ? { marginTop: '-6%' } : {};
    return (
      <div className={styles.more} style={s}>
        <Link target="_blank" to={id
          ? `${url}#${id}`
          : `${url}`}>
          <span>{title}</span>
          <span className={`iconfont iconarrow_right_circle ${styles.icon}`} style={{ fontSize: `${fontSize}` }}></span>
        </Link>
      </div>
    );
  }
}

export default More;
