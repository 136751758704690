import React from 'react';
import More from './more';

const AnkerIntro = ({ temp, location }) => (
  <div className="homeJob">
    {
      temp.title
        ? <p className="title" style={{ marginBottom: '22px' }}>{temp.title}</p>
        : null
    }
    {
      temp.subTitle
        ? <p className="subTitle">{temp.subTitle}</p>
        : null
    }
    <ul className="list">
      {
        temp.image.map((item, i) => (
          <li key={`${item}${i}`}>
            <img className="yellowHoverUpImg" src={item}></img>
            <div dangerouslySetInnerHTML={{ __html: temp.content[i] }} />
          </li>
        ))
      }
    </ul>
    {
      temp.more
        ? <More title={temp.more.text} url={temp.more.url} location={location} />
        : null
    }
  </div>
);

export default AnkerIntro;
