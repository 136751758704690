import React from 'react';
import * as styles from './ankerInfo.module.scss';

const AnkerInfo = ({ temp = {}, odd = 0 }) => {
  const fl = {float: 'left',paddingRight: '3%', left: 0};
  const fr = {float: 'right',paddingLeft: '3%', right: 0};
  return (
    <div className="ankerInfo">
      {
        temp.title?
        <p className={styles.title}>{temp.title}</p>
        :
        null
      }
      
      {
        temp.content.map((item, i) => (
          <div key={`${item}${i}`} className={`ankerInfoWrap clearfix`}>
            <div className="photo hoverPhoto" style={(i + odd)%2 != 1? {float: 'left'}: {float: 'right'}}>
              <img src={temp.image[i]} />
            </div>
            <div className="content" style={(i + odd)%2 != 1? fr: fl}>
              <div dangerouslySetInnerHTML={{ __html: item }} ></div>
            </div>
          </div>
        ))
      }
    </div>
  );
};

export default AnkerInfo;
