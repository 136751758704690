import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';
import { injectIntl } from 'gatsby-plugin-intl';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Swipe from '../components/common/swipe';
// import HomeJob from '../components/home/home-job';
import AnkerIntro from '../components/home/anker-intro';
import AnkerInfo from '../components/home/anker-info';
// import AnkerUserIntro from '../components/home/anker-user-intro';
import * as styles from './index.module.scss';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pageIndex: 1,
      pageSize: 20,
      offsetWidth: 0,
    };
  }

  componentDidMount() {
    const { offsetWidth } = document.getElementById('___gatsby');
    this.setState({ offsetWidth });
    var _hmt = _hmt || [];
    (function () {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?d059262ab27f44a393e7f89bebb1a438";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();
  }

  render() {
    const { offsetWidth } = this.state;
    const { data, location } = this.props;
    const copywriting = data.contentfulCopywriting;
    return (
      <div className={styles.home}>
        {offsetWidth <= 414
          ? copywriting.home.banner && <Swipe items={copywriting.home.banner} mod="home" />
          : copywriting.home.video && <div className="video-wrapper">
            <video width="100%" autoPlay={true} loop="loop" muted="muted" src={copywriting.home.video.url}>
            </video>
            <Link to={copywriting.home.video.jump_link}><div className="video-text-wrapper">
              <div className="videoText">{copywriting.home.video.text}</div>
              <div className="videoSubText1">{copywriting.home.video.subText}</div>
            </div></Link>
          </div>}
        <Layout {...this.props} isHome="true" copywriting={copywriting}>
          {copywriting.home.seo && <SEO
            title={copywriting.home.seo.title}
            keywords={copywriting.home.seo.keywords}
            description={copywriting.home.seo.description} />}
          <div>
            {copywriting.home.intro
              && <AnkerIntro temp={copywriting.home.intro} location={location}></AnkerIntro>}
            {/* {copywriting.home.homejob
              && <HomeJob temp={copywriting.home.homejob} location={location}></HomeJob>} */}
            {copywriting.home.section1
              && <AnkerInfo temp={copywriting.home.section1} location={location}></AnkerInfo>}
            {/* {copywriting.home.section2
              && <AnkerUserIntro
                temp={copywriting.home.section2}
                location={location}></AnkerUserIntro>} */}
          </div>
        </Layout>
      </div>
    );
  }
}

export default injectIntl(Home);

// homejob {
//   image
//   content
//   more {
//     text
//     url
//   }
//   title
// }

export const query = graphql`
query Copywriting($node_locale: String) {
  contentfulCopywriting(node_locale: {eq: $node_locale}) {
    node_locale
    footer {
      brands {
        link
        iconfont
      }
      copyright
      logo
    }
    header {
      links {
        menuId
        name
        url
        children {
          menuId
          name
          url
        }
      }
    }
    search {
      search_btn_text
      placeholder
      options {
        category
        description
        url
      }
    }
    common {
      hot_btn {
        link
        show_paths
        hot_btn_text
      }
    }
    home {
      banner {
        img
        jump_link
      }
      intro {
        content
        image
        title
        more {
          text
          url
        }
      }
      section1 {
        content
        image
        title
      }
      seo {
        description
        keywords
        title
      }
      video {
        subText
        text
        url,
        jump_link
      }
    }
  }
}
`;
